import React, { FC, useState } from 'react'
import { connect } from 'react-redux'
import {  IAppState } from 'interfaces/IAppState'
import {  IAuth } from 'interfaces/IAuth'
import {  IHttp } from 'interfaces/IHttp'
import { registerOrganization } from 'store/actions'
import { useButtonRequest } from 'hooks/useButtonRequest';
import { ActionEnums } from 'enums/ActionEnums'
import ErrorTxt from 'utils/form-validation'
import { useStrictLoader } from 'hooks/useStrictLoader';
import { States } from 'components/register/states'
import AutoInput from 'utils/auto-complete'
import { validatePhoneNumber } from 'utils/helpers'
import './index.scss';
import { pageName, url } from 'enums/Route'

interface IStateProps {
    auth : IAuth;
    http: IHttp;
}

interface IDispatchProps {
    registerOrganization : ( data : object, update? : boolean ) => void;
}

interface IProps extends IStateProps, IDispatchProps{
    registerOrganization : ( adminDetails : object ) => void;
}

const UpdateOrg : FC<IProps> = ( props ) => {

    const { registerOrganization, http, auth } = props;
    const { loggedInDetails } = auth || {};
    const { user } = loggedInDetails || {};
    const { organizationName = '', state = '', phoneNumber = '', email = '' } = user || {};
    const { loading, action } = auth
    const stateOptions = States!?.map(s => s!?.name) 

    let isStatusLoad = useStrictLoader( action, ActionEnums.ORGANIZATION_REGISTRATION ) && loading;

    const initObj = { address : '', organizationName, state, email, phoneNumber, }

    const [ formDetails, setFormDetails ] = useState<{[key : string] : any}>( initObj );
    const [ formError, setFormError ] = useState<{ [key : string] : string }>( );
    const [ otherErrors, setOtherErrors ] = useState<{ [key : string] : string }>();
    const [ otherValidations, setOtherValidations ] = useState(false);

    const handleOnChange = ( e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> ) => {
        setFormError( {} );
        const { name = '', value = '' } = e.target
        switch(name){
            default : setFormDetails(p => ({ ...p, [ name ] : value })); break;
        } 
    };

    const handleLIClick = (e: any ) => {
        const { innerText } = e.currentTarget;
        setFormDetails( prev => ( {...prev, state : innerText } ) ); 
    };

    const onButtonClick = () => {
        validatePhoneNumber( 'phoneNumber', formDetails!?.phoneNumber, setOtherErrors, setOtherValidations )
    };

    const [BTN] = 
    useButtonRequest({ http, loading : isStatusLoad , cTitle: 'Update Organization Information',update : false,
    formAction: registerOrganization, adminAction: action, formState: formDetails, setFormState: setFormDetails, 
    createInitObj: initObj, actionEnums: {create: ActionEnums.ORGANIZATION_REGISTRATION}, otherValidations, 
    setFormError: setFormError, onButtonClick, url: {url: url.ORGANIZATION, pageName: pageName.ORGANIZATION}
    });

    return(
        <div className='create-org'>

            <div className="create-main">
            
                <h3>Update Organization Information</h3>

                    <div className="input-area">
                        <p className="label">Email</p>
                        <input type="text" name='email' onChange={handleOnChange} value={ formDetails!?.email || '' } />
                        <ErrorTxt formError={ formError } value={'email'} />
                    </div>

                    <div className="input-area">
                        <p className="label">Address</p>
                        <textarea name='address' rows={4} onChange={handleOnChange} value={ formDetails!?.address || '' }/>
                        <ErrorTxt formError={ formError } value={'address'} />
                    </div>

                    <div className="input-area">
                        <p className="label">Organization Name</p>
                        <input type="text" name='organizationName' onChange={handleOnChange} 
                            value={ formDetails!?.organizationName || '' } />
                        <ErrorTxt formError={ formError } value={'organizationName'} />
                    </div>

                    <div className="input-area">
                        <p className="label">State</p>
                        <AutoInput options={stateOptions} name='state' onChange={handleOnChange} onLIClick={handleLIClick} 
                            update updateDefaultValue={formDetails!?.state}/>
                        <ErrorTxt formError={ formError } value={'state'} />
                    </div>

                    <div className="input-area">
                        <p className="label">Phone Number</p>
                        <input type="text" name='phoneNumber' placeholder='e.g. +234123456789' onChange={handleOnChange} 
                            value={formDetails!?.phoneNumber || ''} />
                        <ErrorTxt formError={ otherErrors } value={'phoneNumber'} />
                    </div>

                {BTN}
                
            </div>

        </div>
    )
}

const mapStateToProps = (state: IAppState): IStateProps => ({
    auth: state.auth,
    http : state.http
});

const mapDispatchToProps: IDispatchProps = {
    registerOrganization
}

export default connect(mapStateToProps, mapDispatchToProps) ( UpdateOrg );