export const pageurl = {
    LANDING_PAGE: '/',
    EMIAL_VERIFICATION: '/verify-email',
    OVERVIEW: '/dashboard'
};

// export const etraffica_baseurl = process.env.REACT_APP_ETRAFFIKA_BASEURL;

export const etraffica_baseurl = 'https://identitymanager.myapiservices.net/api/v1';
export const identity_server = 'https://identityserver.myapiservices.net/api/v1';
// https://identitymanager.myapiservices.net/api/v1/UserLogin
// export const etraffica_baseurl = 'https://apigateway.myapiservices.net/identity-manager';

export const validEmailRegex = /\S+@\S+\.\S+/;

export const naijaPhoneRegex = /^[+][0-9]\d{9,13}$/;
// /^[+][1-9][0-9]{9,13}$/