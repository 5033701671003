//Reducer Types

//Generic
export const AXIOS_REQUEST = 'AXIOS_REQUEST';
export const AXIOS_REQUEST_SUCCESS = 'AXIOS_REQUEST_SUCCESS';
export const AXIOS_REQUEST_FAILURE = 'AXIOS_REQUEST_FAILURE';
export const SET_TOTAL_COUNT = 'SET_TOTAL_COUNT';
export const SET_TOTAL_SEARCH_COUNT = 'SET_TOTAL_SEARCH_COUNT';
export const SET_ORGANIZATION_AUTH = 'SET_ORGANIZATION_AUTH';
export const SET_PASSWORD_RESET = 'SET_PASSWORD_RESET';
export const SET_VERIFY_PASSWORD_RESET = 'SET_VERIFY_PASSWORD_RESET';
export const SET_VERIFY_EMAIL = 'SET_VERIFY_EMAIL';
export const SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN';
export const SET_GENERATED_TOKEN = 'SET_GENERATED_TOKEN';
export const SET_LOGGED_IN_DETAILS = 'SET_LOGGED_IN_DETAILS';
export const CLEAR_HTTP = 'CLEAR_HTTP';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION'; 
export const SET_HTTP_ACTION = 'SET_HTTP_ACTION'; 
export const SET_HTTP_STATUS = 'SET_HTTP_STATUS';
export const SET_HTTP_METHOD = 'SET_HTTP_METHOD';
export const SET_ADMIN_AUTHENTICATED = 'SET_ADMIN_AUTHENTICATED';
export const SET_ADMIN_UNAUTHENTICATED = 'SET_ADMIN_UNAUTHENTICATED';
export const SET_TABLE_INDEX = 'SET_TABLE_INDEX';

//roles
export const SET_ALL_ROLES = 'SET_ALL_ROLES'; 
export const SET_PERMISSIONS = 'SET_PERMISSIONS'; 

//users
export const SET_ALL_USERS = 'SET_ALL_USERS';

//applications
export const SET_ALL_APPLICATIONS = 'SET_ALL_APPLICATIONS';

//organizations
export const SET_ORGANIZATION_INFO = 'SET_ORGANIZATION_INFO';
export const SET_ALL_ORGANIZATIONS = 'SET_ALL_ORGANIZATIONS';

//api-scopes
export const SET_API_SCOPES = 'SET_API_SCOPES';

//billing
export const SET_API_BUNDLES = 'SET_API_BUNDLES';
export const SET_API_BUNDLE_BY_ID = 'SET_API_BUNDLE_BY_ID';
export const SET_API_CONFIGS = 'SET_API_CONFIGS';
export const SET_API_CONFIG_GROUPS = 'SET_API_CONFIG_GROUPS';
export const SET_CLIENT_SUBSCRIPTIONS = 'SET_CLIENT_SUBSCRIPTIONS';
